import { useState, useRef, useEffect, useCallback } from 'react'
import { Text, BannerAlert, Spacer } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import type { Dispensed, FfsInventoryInfo } from '@truepill/tpos-types'
import { ClaimDataPaidStatus, RxFillRequestStatus, CopayStatus, SystemTriageReasons } from '@truepill/tpos-types'
import { ReactComponent as LoadingDots } from 'assets/icons/loading_dots.svg'
import { SaveButton } from 'components/ActionButton'
import CriticalMedicalNoteWarning from 'components/CriticalMedicalNoteWarning'
import CustomizedTextField from 'components/CustomizedTextField'
import type { OnChangeParams } from 'components/DirectionsInput'
import { MemoizedDirectionsInput } from 'components/DirectionsInput'
import HyphenatedNdc from 'components/HyphenatedNdc'
import IconWrapper from 'components/IconWrapper'
import InventorySystemTag from 'components/InventorySystemTag'
import { ModalHeader, ModalTitle, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { MemoizedSubstitutionSelect as SubstitutionSelect } from 'components/SubstitutionSelect'
import { SET_DISPENSED, CONFIRM_FILL_ADJUDICATION, CONFIRM_COPAY_REQUEST_FILL } from 'gql'
import { updateClaimsRequest, mapSubmissionResponse } from 'hooks/navigation/useClaim'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useAnalytics from 'hooks/useAnalytics'
import useNdcValidationInfo from 'hooks/useNdcValidationInfo'
import { useValidateQuantity, ValidateQuantityContexts, QuantityErrors } from 'hooks/useValidateQuantity'
import WrappedClaimsSuccessModal from 'modals/ClaimsSuccessModal'
import SimpleModal from 'modals/SimpleModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { PV1Events } from 'services/analytics/events/pv1Events'
import styled, { css } from 'styled-components'
import { borderColor, contrastBackgroundColor, btnPrimary } from 'styles/styleVariables'
import type {
  Order,
  CopayRequest,
  Fill,
  Prescription,
  NdcSubstitutionPackage,
  InventoryGroup,
  FormularyResult,
  NdcSubstitutionPackagePreferred,
} from 'types'
import {
  isOrder,
  getPackageMeasureDescription,
  shortLabelForDAW,
  isInsuranceFill,
  directionsLengthDetails,
  floatSafeRemainder,
  languagesSupported,
  getItemFill,
  isCopayRequest,
  isRxFillRequest,
  stripTypename,
} from 'utils'
import { twoDecimalOrLess } from 'utils/numberFormatter'
import WrappedClaimsRejectedModal from './ClaimsRejectedModal'

type ChangeDispensedProps = {
  fill: Fill
  prescription: Prescription
  item: Order | CopayRequest
  ndcSubstitutionPackage?: NdcSubstitutionPackage
}

// Technicians and CSAs cannot update the directions if it's a TPOS refill
// This means that it's a refill and it's not the first one on TPOS
// (previous fills filled in pioneer do not count)
const useEditDirectionsPermissions = (fill: Fill, prescription: Prescription) => {
  const {
    tokenContext: { isPharmacist, isLeadPharmacist, isAdmin },
  } = usePlusClient()

  const hasCorrectPermissions = isAdmin() || isPharmacist() || isLeadPharmacist()

  const isFirstFill = fill.fillNumber === 0

  const allPreviousFillsWhereFilledInPioneer = prescription.fills
    .filter(currentFill => currentFill.fillNumber < fill.fillNumber)
    .sort((firstFill, secondFill) => firstFill.fillNumber - secondFill.fillNumber)
    .every(currentFill => currentFill.isPioneerHistory)

  const isTposRefill = !isFirstFill && !allPreviousFillsWhereFilledInPioneer
  const canEditRefills = isFirstFill || isAdmin()
  const canEditDirections = hasCorrectPermissions || !isTposRefill

  return canEditDirections && canEditRefills
}

const ChangeDispensed = ({ fill, item, prescription }: ChangeDispensedProps): JSX.Element => {
  const { showModal, dismissModal } = useModalContext()
  const showSuccess = useSuccessToast()
  const showErrorToast = useErrorToast()
  const {
    tokenContext: { isAdmin },
  } = usePlusClient()
  const { trackPV1Event } = useAnalytics()

  const itemFill = isCopayRequest(item) ? getItemFill(item, fill._id) : getItemFill(item, fill._id)

  const [ndcInventoryGroupAlias, setNdcInventoryGroupAlias] = useState(
    // TODO: FIX TYPES
    (fill as Fill & { dispensed: Dispensed & { inventoryGroup: InventoryGroup } }).dispensed.inventoryGroup
      ?.pioneerAlias ?? '',
  )
  const [sigCode, setSigCode] = useState<string | undefined>(fill.dispensed.sigCode)
  const [quantity, setQuantity] = useState<number | undefined>(fill.dispensed.quantity)
  const [daysSupply, setDaysSupply] = useState<number | undefined>(fill.dispensed.daysSupply)
  const [directions, setDirections] = useState<string | undefined>(fill.dispensed.directions)
  const [ndcInventoryGroupId, setNdcInventoryGroupId] = useState<string | undefined>(
    fill.dispensed.inventoryGroupId?.toString(),
  )
  const [ffsInventoryInfo, setFfsInventoryInfo] = useState<FfsInventoryInfo | undefined>(
    fill.dispensed.ffsInventoryInfo && Object.keys(fill.dispensed.ffsInventoryInfo).length > 0
      ? {
          ...stripTypename(fill.dispensed.ffsInventoryInfo),
        }
      : undefined,
  )
  const [fillNdc, setFillNdc] = useState(fill.dispensed.ndc ?? '')
  const [formularyInfo, setFormularyInfo] = useState<FormularyResult | undefined>()
  const [drugName, setDrugName] = useState(fill?.dispensed?.name)
  const [needsReadjudication, setNeedsReadjudication] = useState<boolean>(false)
  const [isTherapeuticalEquivalent, setIsTherapeuticalEquivalent] = useState<boolean>(false)

  const isInAdjudication = isOrder(item)
    ? itemFill?.status === RxFillRequestStatus.Adjudication
    : itemFill?.status !== CopayStatus.Complete && itemFill?.status !== CopayStatus.Rejected
  const isComplete = isOrder(item)
    ? itemFill?.status === RxFillRequestStatus.Complete
    : itemFill?.status === CopayStatus.Complete

  // We are allowing changing dispense on complete fills for pharmacists to reverse and rerun claims with updated
  // days supply.  When in this scenario we limit functionality to only update the day supply (unless admin)
  const limitedAccess = isComplete && !isAdmin()

  const orderHasSystemTriage = isOrder(item)
    ? item.triages
        .filter(triage => !triage.endDate)
        .find(triage => Object.keys(SystemTriageReasons).includes(triage.reason)) !== undefined
    : false

  const { ndcValidationInfo } = useNdcValidationInfo(fillNdc, isAdmin())
  const { requiresUnitDosePackaging, packageSize, packageUnitOfMeasure } = ndcValidationInfo
  const isPartialPackage = requiresUnitDosePackaging && floatSafeRemainder(quantity || 0, packageSize) !== 0

  const handleDirectionsChange = useCallback(({ directions, sigCode }: OnChangeParams) => {
    setDirections(directions)
    setSigCode(sigCode)
    trackPV1Event(PV1Events.PV1_CHANGE_DISPENSED_DIRECTIONS.key, {
      fillId: fill._id,
    })
  }, [])

  useEffect(() => {
    const hasEditedReverseAndRebillFields =
      fill.dispensed.ndc !== fillNdc || fill.dispensed.quantity !== quantity || fill.dispensed.daysSupply !== daysSupply
    const isInsurance = isRxFillRequest(itemFill) ? isInsuranceFill(itemFill) : true
    setNeedsReadjudication(hasEditedReverseAndRebillFields && isInsurance && !isInAdjudication && !isComplete)
  }, [fill, quantity, daysSupply, fillNdc, isInAdjudication, isComplete, itemFill, item])

  const [updateDispensedMedication, { client, loading }] = useMutation(SET_DISPENSED, {
    onCompleted(data) {
      dismissModal()
      const { updateDispensed: fill } = data
      trackPV1Event(PV1Events.PV1_CHANGE_DISPENSED_UPDATE.key, {
        fillId: fill?._id,
      })

      if (needsReadjudication) {
        if (fill?.claimSummary?.runSuccessful && itemFill) {
          showModal(() => <WrappedClaimsSuccessModal item={item} fill={fill} itemFill={itemFill} />)
        } else {
          showErrorToast('Successfully updated dispensed medication but failed to rerun claim, try manually')
          showModal(() => <WrappedClaimsRejectedModal orderId={item['_id']} fill={fill} />)
        }
      } else {
        showSuccess(`Updated dispensed medication item.`)
      }
    },
    onError(err) {
      client.refetchQueries({ include: ['getBasicOrder'] })
      dismissModal()
      showErrorToast('Failed to update dispensed medication: ' + err.message)
    },
  })
  const [confirmFillAdjudication, { loading: adjudicationLoading }] = useMutation(CONFIRM_FILL_ADJUDICATION, {
    refetchQueries: ['getBasicOrder'],
    onCompleted(data) {
      const { confirmFillAdjudication } = data
      const { claims } = confirmFillAdjudication
      const lastClaim = claims[claims.length - 1]

      if (ClaimDataPaidStatus.includes(lastClaim.status)) {
        showSuccess(`Successfully ran claims!`)
        if (isComplete && itemFill) {
          showModal(() => (
            <WrappedClaimsSuccessModal
              fill={fill}
              itemFill={itemFill}
              item={item}
              showReverse
              updatedDispensed={{
                ndc: fill.dispensed.ndc,
                name: fill.dispensed.name,
                quantity,
                daysSupply,
                directions,
              }}
              claim={lastClaim}
            />
          ))
        }
      }
    },
  })

  const [confirmCopayRequestFill, { loading: copayClaimLoading }] = useMutation(CONFIRM_COPAY_REQUEST_FILL, {
    refetchQueries: ['getBasicCopay'],
    onCompleted(data) {
      const { confirmCopayRequestFill } = data
      const { claims } = confirmCopayRequestFill
      const lastClaim = claims[claims.length - 1]

      if (ClaimDataPaidStatus.includes(lastClaim.status)) {
        showSuccess(`Successfully ran claims!`)
      }
    },
  })

  const buttonIcon = loading ? (
    <LoadingIconWrapper>
      <LoadingDots />
    </LoadingIconWrapper>
  ) : undefined
  const dawLabel = shortLabelForDAW(prescription.daw)
  const labelText = needsReadjudication ? 'Reverse & rebill' : 'Update'
  const buttonLabel = loading || adjudicationLoading || copayClaimLoading ? '' : labelText
  const updateAndRunButtonLabel = loading || adjudicationLoading || copayClaimLoading ? '' : 'Update & Run Claim'
  const directionsRef = useRef({ style: { height: '' }, scrollHeight: 0 })
  const handleSubstitutionChange = useCallback(() => setFillNdc(''), [])
  const handleSubstitutionSelect = useCallback(
    (
      {
        ndc,
        drugName,
        inventoryGroup,
        acquisitionCost,
        preferred,
        consigned,
        ffsInventoryInfo,
      }: NdcSubstitutionPackagePreferred,
      wasManuallySearched: boolean,
      formularyStatus?: boolean,
      isNonTherapeuticEquivalent?: boolean,
    ) => {
      setIsTherapeuticalEquivalent(!isNonTherapeuticEquivalent && !wasManuallySearched)
      setFillNdc(ndc)
      setNdcInventoryGroupAlias(inventoryGroup?.pioneerAlias)
      setNdcInventoryGroupId(inventoryGroup?._id.toString())
      setDrugName(drugName)
      setFfsInventoryInfo(
        ffsInventoryInfo && Object.keys(ffsInventoryInfo).length > 0
          ? stripTypename({ ...ffsInventoryInfo })
          : undefined,
      )
      // set formulary info into the state so it can be send in the patch used to update dispensed data
      if (formularyStatus !== undefined) {
        setFormularyInfo({
          onFormulary: formularyStatus,
          ...(acquisitionCost !== null && { acquisitionCost }),
          ...(preferred !== null && { preferred }),
          ...(consigned !== null && { consigned }),
        })
      }
      trackPV1Event(PV1Events.PV1_CHANGE_DISPENSED_NDC.key, {
        fillId: fill._id,
      })
    },
    [],
  )

  useEffect(() => {
    directionsRef.current.style.height = '0px'
    const scrollHeight = directionsRef.current.scrollHeight + 10
    directionsRef.current.style.height = scrollHeight + 'px'
  }, [directions])

  const [qtyError, qtyText, qtyErrorEnum] = useValidateQuantity(
    quantity || 0,
    prescription.quantityRemaining,
    ndcValidationInfo,
    ValidateQuantityContexts.ChangeDispensed,
  )
  const dsError = !daysSupply || daysSupply < 0 || isNaN(daysSupply)
  const directionsError = !directions || directions.includes('$')
  const fillNdcError = !fillNdc

  //JR-9984 we can ignore this qtyError if it is a replacement order
  const isDispenseWholePackageError = qtyErrorEnum === QuantityErrors.DispenseWholePackage
  const isReplacement = isOrder(item) ? item.isReplacement : false

  const isUpdateButtonDisabled =
    (qtyError && !isReplacement) ||
    (qtyError && isReplacement && !isDispenseWholePackageError) ||
    dsError ||
    directionsError ||
    fillNdcError

  const directionsDetails = directionsLengthDetails(!!fill.genericFor, directions)
  const canEditDirections = useEditDirectionsPermissions(fill, prescription)

  const displayQuantity = isRxFillRequest(itemFill) ? itemFill.quantity : itemFill?.dispensed?.quantity

  return (
    <ModalWrapper id="ChangeDispensedModal" styles={modalWrapperStyles}>
      <ModalHeader>
        <ModalTitle>Change dispensed medication</ModalTitle>
      </ModalHeader>
      <Spacer desktop="lg" />
      {prescription.patient.preferences?.language && prescription.patient.preferences?.language !== 'en' && (
        <BannerAlert
          css={{
            padding: '15px',
          }}
        >
          <Text>
            The preferred language for this patient is
            <strong style={{ fontWeight: 'bold' }}>
              {' '}
              {languagesSupported.find(({ key }) => prescription.patient.preferences?.language === key)?.language}
            </strong>
            .
          </Text>
        </BannerAlert>
      )}
      <TitlesContainer>
        <Title>Prescribed medication</Title>
        <Title>Dispensed medication</Title>
      </TitlesContainer>
      <FormContainer>
        <FormRow>
          <RowItemContainer>Medication:</RowItemContainer>
          <RowItemContainer data-testid="current-medication">
            {prescription.name}
            <EM>(DAW: {dawLabel})</EM>
          </RowItemContainer>
          <InputContainer>
            {!limitedAccess ? (
              <SubstitutionSelect
                isModal
                preventScrolling={true}
                data-testid="new-medication"
                prescription={prescription}
                fill={fill}
                dispensedNdc={fillNdc}
                dispensedInventoryGroupId={ndcInventoryGroupId}
                orderId={item._id}
                onChange={handleSubstitutionChange}
                onSelect={handleSubstitutionSelect}
              />
            ) : (
              <DispensedText>{fill.dispensed.name}</DispensedText>
            )}
            <CriticalMedicalNoteWarning style={{ marginTop: '0.5rem', marginBottom: '0.25rem' }} ndc={fillNdc} />
          </InputContainer>
        </FormRow>
        <FormRow>
          <RowItemContainer>NDC:</RowItemContainer>
          <RowItemContainer data-testid="current-ndc">
            <HyphenatedNdc ndc={prescription.ndc} />
            <Spacer size="xs" />
            <InventorySystemTag externalSystemName={fill.dispensed.ffsInventoryInfo?.externalSystemName} />
          </RowItemContainer>
          <RowItemContainer data-testid="new-ndc">
            <HyphenatedNdc ndc={fillNdc} /> {ndcInventoryGroupAlias ? `(${ndcInventoryGroupAlias})` : ''}
            <Spacer size="xs" />
            <InventorySystemTag externalSystemName={ffsInventoryInfo?.externalSystemName} />
          </RowItemContainer>
        </FormRow>
        <FormRow>
          <RowItemContainer>Quantity:</RowItemContainer>
          <RowItemContainer data-testid="current-qty">
            {prescription.quantity} {getPackageMeasureDescription(packageUnitOfMeasure)}
          </RowItemContainer>
          <RowItemContainerQty data-testid="new-qty">
            {!limitedAccess ? (
              <CustomizedTextField
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setQuantity(Number(e.target.value) || undefined)
                  trackPV1Event(PV1Events.PV1_CHANGE_DISPENSED_QUANTITY.key, {
                    fillId: fill._id,
                  })
                }}
                size="sm"
                value={quantity}
                min={0}
                max={prescription.quantityRemaining}
                helperText={qtyText}
                state={qtyError ? 'error' : undefined}
                style={{
                  width: '100px',
                  padding: qtyError ? '0.2em 40px 0.2em 11px' : '0.2em 11px',
                }}
              />
            ) : (
              <DispensedText>{quantity}</DispensedText>
            )}
            <PrescriptionQtyStyled>
              <PackageSizeUnitOfMeasure>
                {getPackageMeasureDescription(fill.dispensed.packageSizeUnitOfMeasure)}
              </PackageSizeUnitOfMeasure>
              <section>
                <span>
                  <b>Requested: </b> {displayQuantity}
                  {getPackageMeasureDescription(fill.dispensed.packageSizeUnitOfMeasure)}
                </span>
              </section>
              <section>
                <span>
                  <b>Remaining: </b> {twoDecimalOrLess(prescription.quantityRemaining)}
                  {getPackageMeasureDescription(fill.dispensed.packageSizeUnitOfMeasure)}
                </span>
              </section>
            </PrescriptionQtyStyled>
          </RowItemContainerQty>
        </FormRow>
        <FormRow>
          <RowItemContainer>DS:</RowItemContainer>
          <RowItemContainer data-testid="current-ds">{prescription.daysSupply}</RowItemContainer>
          <RowItemContainerQty data-testid="new-ds">
            <CustomizedTextField
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDaysSupply(Number(e.target.value) || undefined)
                trackPV1Event(PV1Events.PV1_CHANGE_DISPENSED_DS.key, {
                  fillId: fill._id,
                })
              }}
              size="sm"
              value={daysSupply}
              min={0}
              max={prescription.daysSupply}
              helperText={dsError ? 'Days supply is a required field' : undefined}
              state={dsError ? 'error' : undefined}
              style={{
                width: '100px',
                padding: dsError ? '0.2em 40px 0.2em 11px' : '0.2em 11px',
              }}
            />
          </RowItemContainerQty>
        </FormRow>
        <FormRow>
          <RowItemContainer>Directions:</RowItemContainer>
          <RowItemContainer> {prescription.directions}</RowItemContainer>
          <InputContainer data-testid="new-directions">
            {!limitedAccess ? (
              <MemoizedDirectionsInput
                ref={directionsRef}
                disabled={!canEditDirections}
                patientId={prescription.patientId}
                directionsValue={directions ?? ''}
                sigCodeValue={sigCode}
                maxLength={directionsDetails.maxLength}
                onChange={handleDirectionsChange}
                helperText={directionsError ? 'Directions is a required field' : undefined}
                state={directionsError ? 'error' : 'default'}
                errorText="Directions may print on leaflet instead of Rx label"
              />
            ) : (
              <DispensedText>{directions}</DispensedText>
            )}
          </InputContainer>
        </FormRow>
      </FormContainer>
      <ButtonsContainer>
        {isReplacement && isPartialPackage && (
          <Text variant="body" css={{ color: 'red' }}>
            Warning: Submitting this quantity will result in breaking apart of package for this Replacement Order.
          </Text>
        )}
        <CancelButton label="Cancel" onClick={dismissModal} />
        <StyledActionButton
          isModal
          data-testid="save"
          disabled={isUpdateButtonDisabled}
          icon={buttonIcon}
          label={buttonLabel}
          onClick={() => {
            if (fill.dispensed.ndc !== fillNdc && !isTherapeuticalEquivalent && !loading) {
              showModal(() => (
                <SimpleModal
                  title={'Are you sure?'}
                  confirmButtonLabel={'Update'}
                  cancelButtonLabel={'Cancel'}
                  confirmationCallback={() => {
                    if (!loading) {
                      updateDispensedMedication({
                        variables: {
                          itemId: item._id,
                          fillId: fill._id,
                          ndc: fillNdc,
                          sigCode,
                          quantity,
                          daysSupply,
                          directions,
                          name: drugName,
                          formularyResult: formularyInfo,
                          inventoryGroupId: ndcInventoryGroupId,
                          ffsInventoryInfo,
                          isCopay: isCopayRequest(item),
                        },
                      })
                    }
                  }}
                >
                  <p>
                    {
                      'The dispensed medication you have selected is not therapeutically equivalent to the prescribed NDC. Do you want to proceed?'
                    }
                  </p>
                </SimpleModal>
              ))
            } else if (!loading) {
              const ndcChanged = fill.dispensed.ndc !== fillNdc
              updateDispensedMedication({
                variables: {
                  itemId: item._id,
                  fillId: fill._id,
                  sigCode,
                  ndc: ndcChanged ? fillNdc : undefined,
                  quantity,
                  daysSupply,
                  directions,
                  formularyResult: formularyInfo,
                  name: ndcChanged ? drugName : undefined,
                  inventoryGroupId: ndcInventoryGroupId,
                  ffsInventoryInfo,
                  isCopay: isCopayRequest(item),
                },
              })
            }
          }}
        />
        {(isInAdjudication || isComplete) && (
          <StyledActionButton
            isModal
            disabled={qtyError || dsError || directionsError || orderHasSystemTriage}
            data-testid="saveAndRun"
            icon={buttonIcon}
            label={updateAndRunButtonLabel}
            onClick={async () => {
              if (!loading) {
                await updateDispensedMedication({
                  variables: {
                    itemId: item._id,
                    fillId: fill._id,
                    ndc: fillNdc,
                    quantity,
                    daysSupply,
                    directions,
                    name: drugName,
                    inventoryGroupId: ndcInventoryGroupId,
                    ffsInventoryInfo,
                    isCopay: isCopayRequest(item),
                  },
                })
                try {
                  updateClaimsRequest({
                    claimsSubmissionResponse: {
                      rejectionMessages: [],
                      additionalInfo: '',
                      successfulClaim: false,
                    },
                    claimsSubmitting: true,
                  })

                  let response
                  if (isOrder(item)) {
                    response = await confirmFillAdjudication({
                      variables: {
                        orderId: item._id,
                        fillId: fill._id,
                      },
                    })
                  } else if (isCopayRequest(item)) {
                    response = await confirmCopayRequestFill({
                      variables: {
                        copayRequestId: item._id,
                        fillId: fill._id,
                      },
                    })
                  }

                  const newClaims = isOrder(item)
                    ? response?.data?.confirmFillAdjudication.claims
                    : response?.data?.confirmCopayRequestFill?.claims
                  const lastClaim = newClaims[newClaims.length - 1]
                  updateClaimsRequest({
                    claimsSubmissionResponse: mapSubmissionResponse(lastClaim),
                    claimsSubmitting: false,
                  })
                } catch (e) {
                  console.error(e)
                  updateClaimsRequest({
                    claimsSubmissionResponse: {
                      rejectionMessages: [],
                      additionalInfo: 'An error occurred sending the claim',
                      successfulClaim: false,
                    },
                    claimsSubmitting: false,
                  })
                  showErrorToast((e as { message: string }).message)
                }
              }
            }}
          />
        )}
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const modalWrapperStyles = css`
  padding: 1.5rem;
  font-family: Lato;
`

const EM = styled.em`
  font-weight: bold;
  font-size: 0.875;
`

const StyledActionButton = styled(SaveButton)`
  background-color: ${btnPrimary};
  min-width: 101px;
  display: block;
  text-align: left;
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
  color: white;
  text-align: center;
`

const LoadingIconWrapper = styled(IconWrapper)`
  display: inline-block;
  vertical-align: middle;
  float:right
  margin-right: -10px;
`
const FormContainer = styled.ul`
  display: flex;
  width: 61.75rem;
  flex-direction: column;
`

const PrescriptionQtyStyled = styled.section`
  display: flex;
  height: 48px;
  position: absolute;
  left: 110px;
  top: 8px;
  width: calc(100% - 110px);
  > section {
    display: flex !important;
    flex: 50%;
    border-radius: 8px;
    margin-left: 3%;
    background-color: ${contrastBackgroundColor};
    > span {
      position: absolute;
      text-align: center;
      width: 47%;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      > b {
        font-weight: bold;
      }
    }
  }
`

const FormRow = styled.li`
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
  flex: 1;
  display: grid;
  grid-template-columns: 1.5fr 4.25fr 4.25fr;
  > div:first-child {
    font-weight: bold;
  }
`
const TitlesContainer = styled.div`
  flex: 1;
  display: grid;
  margin: 0.5rem 0;
  grid-template-columns: 1.5fr 4.25fr 4.25fr;
  line-height: 1.25rem;
  > p:first-child {
    grid-column-start: 2;
  }
`

const Title = styled.p`
  flex: 1;
  font-size: 1.5rem;
  line-height: 30px;
  font-weight: 700;
  padding: 0.315rem;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.25rem;
`
const RowItemContainer = styled.div`
  width: 100%;
  min-height: 36px;
  padding: 0.315rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  :first-child {
    font-size: 0.875rem;
    font-weight: 500;
  }
`
const RowItemContainerQty = styled.div`
  position: relative;
  width: 100%;
  min-height: 64px;
  padding: 8px 8px 8px 5px;
  display: flex;
  justify-content: normal;
  align-items: center;
  :first-child {
    font-size: 0.875rem;
    font-weight: 500;
  }
`
const InputContainer = styled(RowItemContainer)`
  position: relative;
  > textarea {
    width: 100%;
    border: 0.125rem solid ${borderColor};
    padding: 0.4em 40px 0.4em 11px;
  }
`

const PackageSizeUnitOfMeasure = styled.span`
  display: flex;
  align-items: center;
`

const DispensedText = styled.div``

export default ChangeDispensed
