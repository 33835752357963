import type { Dispatch, SetStateAction } from 'react'
import { useState, useEffect } from 'react'
import { isCashPaymentType } from '@truepill/tpos-data-util'
import { useQuery } from '@truepill/tpos-react-router'
import { UserRoles, FillStatus, RxFillRequestStatus, OrderStatus } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import BreadCrumb from 'components/BreadCrumb'
import DuplicateRxBanner from 'components/DuplicateRxBanner'
import FillRequestDetails from 'components/FillRequestDetails'
import { PageContent, PageHeading, StickyPageHeadingContainer } from 'components/PageStructure'
import ReviewPatient from 'components/ReviewPatient'
import ReviewPrescription from 'components/ReviewPrescription'
import RxHeading from 'components/RxHeading'
import RxReviewPrescriber from 'components/RxReviewPrescriber'
import { RXRowTable, RXSegment, RXTable } from 'components/RXTable'
import RXTopBanner from 'components/RXTopBanner'
import ToolBar, {
  DataButton,
  EditButton,
  LogButton,
  MedicalHistoryButton,
  MonographButton,
  ClaimSummaryButton,
  NotesButton,
  OrderButton,
  AttachmentsButton,
  DispensingDecisionButton,
  EditReversalInfoButton,
} from 'components/ToolBar'
import TriageIssues from 'components/TriageIssues'
import { GET_PRESCRIPTION_WARNINGS } from 'gql'
import { useUserInfo } from 'hooks'
import { uniqueLogEntries } from 'hooks/navigation/useLogs'
import useAnalytics from 'hooks/useAnalytics'
import { useLastFillDiff } from 'hooks/useLastFillDiff'
import useLogPageView from 'hooks/useLogPageView'
import { usePopup } from 'hooks/usePopup'
import useRunClinicalReview from 'hooks/useRunClinicalReview'
import DurInputMappingsModal from 'modals/DurInputMappingsModal/DurInputMappingsModal'
import { EditUracModal } from 'modals/EditUracModal'
import MedicalHistoryModal from 'modals/MedicalHistoryModal'
import MismatchedPatientPrescriptionIdModal from 'modals/MismatchedPatientPrescriptionIdModal'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { PV1Events } from 'services/analytics/events/pv1Events'
import styled from 'styled-components'
import type { TPOSDocument, Log, RXFillRequest, Fill, Patient, Order, Prescription } from 'types'
import { getOrderAttachmentsCount, getParentAttachments, isOrderReadOnly } from 'utils'
import ReviewSafety from '../../components/ReviewSafety'
import EditReversalInfoView from '../claims/editReversalInfoView'
import ActionButtons from './ActionButtons'
import { usePV1FeatureFlags } from './usePV1FeatureFlags'

const useMismatchedPatientFillModal = (prescription?: Prescription, order?: Order, patientId?: string) => {
  const { showModal } = useModalContext()

  useEffect(() => {
    if (!prescription || !order || !patientId || !showModal) {
      return
    }

    if (prescription.patientId !== patientId) {
      showModal(() => <MismatchedPatientPrescriptionIdModal order={order} prescription={prescription} />)
    }
  }, [prescription, order, patientId])
}

const useMedicalHistoryModal = (patientId: string, fillId: string, rxFillRequest: RXFillRequest) => {
  const { showModal } = useModalContext()
  useEffect(() => {
    if (!showModal) {
      return
    }
    const isControlledSubstance = rxFillRequest.prescription.isControlledSubstance
    if (isControlledSubstance) {
      showModal(() => {
        return <MedicalHistoryModal patientId={patientId} fillId={fillId} />
      })
    }
  }, [patientId, rxFillRequest.prescription.isControlledSubstance])
}

type PV1ViewProps = {
  notesBadgeCount: number
  patient: Patient
  prescription: Prescription
  order: Order
  fill: Fill
  rxFillRequest: RXFillRequest
  docs?: TPOSDocument[]
  logs: Log[]
  disableRphButtons: boolean
  forceLoadingSpinner?: Dispatch<SetStateAction<boolean>>
}

interface PrescriptionWarning {
  prescriptionId: string
  type?: string
}

const PV1View = ({
  patient,
  prescription,
  order,
  notesBadgeCount,
  fill,
  rxFillRequest,
  docs,
  logs,
  disableRphButtons,
  forceLoadingSpinner,
}: PV1ViewProps): JSX.Element => {
  const { _id: patientId } = patient
  const { _id: prescriptionId, customer } = prescription

  const [canConfirm, setCanConfirm] = useState<boolean>()
  const [showDurModal, setShowDurModal] = useState(false)
  const [displayEditReversalInfo, setDisplayEditReversalInfo] = useState(false)

  const {
    tokenContext: { id },
  } = usePlusClient()
  const { userInfo } = useUserInfo(id)
  const { trackPV1Event } = useAnalytics()
  const { sendMessageToPopup } = usePopup()

  useLogPageView({
    page: `order/${rxFillRequest.status}${order.inTriage ? '/Triage' : ''}`,
    orderId: order._id,
    fillId: fill._id,
  })

  useCriticalNotesModal({
    order,
    prescription,
    logs,
    showOrderFillNotes: true,
    showPatientNotes: true,
    showPrescriberNotes: true,
  })

  useMismatchedPatientFillModal(prescription, order, patientId)
  useMedicalHistoryModal(patientId, fill._id, rxFillRequest)

  // Feature flags
  const {
    newDurInputsModalEnabled,
    hideConsentOnPv1,
    hideRefillsWrittenField,
    highlightPv1Differences,
    multiPatientOrder,
    shouldApplyInterfaceImprovements,
    showCopayCollected,
  } = usePV1FeatureFlags({ customerLegacyId: customer?.legacyId, userEmail: userInfo?.email })

  const durInputs = (multiPatientOrder ? rxFillRequest.durInputs : order.durInputs) || {
    allergies: [],
    conditions: [],
    medications: [],
  }

  useEffect(() => {
    sendMessageToPopup<{ prescriptionId: string; source: string }>({
      prescriptionId,
      source: 'vision',
    })

    return () => {
      sendMessageToPopup<{ prescriptionId: string; source: string }>({ prescriptionId: '', source: 'vision' })
    }
  }, [prescriptionId, sendMessageToPopup])

  const { data } = useQuery<{ getPrescriptionWarnings: PrescriptionWarning[] }>(GET_PRESCRIPTION_WARNINGS, {
    variables: { prescriptionId },
  })
  const prescriptionWarnings = data?.getPrescriptionWarnings ?? []
  const duplicatePrescriptionWarning = prescriptionWarnings.filter(warning => warning.type === 'Duplicate Prescription')

  const {
    runClinicalReview,
    loading: clinicalReviewIsLoading,
    error: clinicalReviewError,
  } = useRunClinicalReview({
    onCompleted: () => {
      setShowDurModal(false)
    },
    onError: () => {
      setShowDurModal(false)
    },
  })

  const {
    dispensedFields: highlightedDispensedFields,
    prescribedFields: highlightedPrescribedFields,
    prescriberFields: highlightedPrescriberFields,
    patientFields: highlightedPatientFields,
  } = useLastFillDiff(
    {
      prescription,
      fill,
    },
    { skip: !highlightPv1Differences },
  )

  if (!order || !fill || !prescription || !patient) {
    return <></>
  }

  const fillNotCancelled =
    fill.status !== FillStatus.Cancelled && rxFillRequest.status !== RxFillRequestStatus.Cancelled

  const orderInPV1 = order.status === OrderStatus.PV1
  const orderInTriage = order.inTriage
  const orderReadonly = isOrderReadOnly(order)

  const allowClaimSummaryButton = (fill?.claims?.length ?? 0) > 0
  const failedClaimReversals = fill.claims.find(claim => claim?.status === 'Paid' && claim?.failedReversals?.length > 0)

  const orderAttachments = getOrderAttachmentsCount(docs)
  const parentAttachments = getParentAttachments(docs)

  if (displayEditReversalInfo) {
    if (!order || !rxFillRequest) {
      return <></>
    }
    return (
      <EditReversalInfoView
        item={order}
        itemFill={rxFillRequest}
        prescription={prescription}
        patient={patient}
        fill={fill}
        setDisplayEditReversalInfo={setDisplayEditReversalInfo}
      />
    )
  }

  return (
    <>
      <StickyPageHeadingContainer>
        <RXTopBanner order={order} rxFillRequest={rxFillRequest} />
        {/* We don't want the DuplicateRxBanner to appear in Adjudication & Cancelled pages */}
        {fillNotCancelled && orderInPV1 && !orderInTriage ? (
          duplicatePrescriptionWarning.length > 0 && (
            <DuplicateRxBanner
              mainPrescriptionId={prescriptionId}
              duplicatePrescriptionsIds={duplicatePrescriptionWarning.map(p => p.prescriptionId)}
              orderId={order._id}
            />
          )
        ) : (
          <></>
        )}
        <StyledBreadCrumb />
        <PageHeading>
          <RxHeading item={order} prescription={prescription} fill={fill} patient={patient} />
          {fillNotCancelled && (
            <ActionButtons
              order={order}
              fill={fill}
              prescription={prescription}
              rxFillRequest={rxFillRequest}
              canConfirm={canConfirm}
              disableRphButtons={disableRphButtons}
              forceLoadingSpinner={forceLoadingSpinner}
              onPrescribedNdcUpdated={() => {
                runClinicalReview({
                  variables: {
                    orderId: order._id,
                    fillId: fill._id,
                  },
                })
              }}
            />
          )}
        </PageHeading>
      </StickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          {order.inTriage && <TriageIssues item={order} fill={fill} itemFill={rxFillRequest} />}
          <RXTable>
            <RXSegment>
              <RXRowTable>
                <ReviewPatient
                  patient={patient}
                  prescription={prescription}
                  rightContent={
                    <FillRequestDetails
                      fill={fill}
                      hideConsent={hideConsentOnPv1}
                      order={order}
                      prescription={prescription}
                      patient={patient}
                      rxFillRequest={rxFillRequest}
                      showCopayCollected={showCopayCollected && !isCashPaymentType(rxFillRequest?.paymentType ?? '')}
                    />
                  }
                  rightHeader={<>Fill Request</>}
                  highlightedFields={highlightedPatientFields}
                  shouldApplyInterfaceImprovements={shouldApplyInterfaceImprovements}
                />
                <RxReviewPrescriber prescription={prescription} highlightedFields={highlightedPrescriberFields} />
                <ReviewPrescription
                  item={order}
                  prescription={prescription}
                  fill={fill}
                  setCanConfirm={setCanConfirm}
                  hideRefillsWrittenField={hideRefillsWrittenField}
                  highlightedDispensedFields={highlightedDispensedFields}
                  highlightedPrescribedFields={highlightedPrescribedFields}
                />
                <ReviewSafety
                  order={order}
                  fill={fill}
                  rxFillRequest={rxFillRequest}
                  prescription={prescription}
                  showEditUracModal={() => {
                    setShowDurModal(true)
                  }}
                  clinicalReviewError={clinicalReviewError}
                  isClinicalReviewLoading={clinicalReviewIsLoading}
                  onRerunClinicalReviewClick={() => {
                    runClinicalReview({
                      variables: {
                        orderId: order._id,
                        fillId: fill._id,
                      },
                    })
                  }}
                />
                {showDurModal &&
                  (newDurInputsModalEnabled ? (
                    <DurInputMappingsModal
                      currentDurInputs={durInputs}
                      survey={order.survey}
                      orderId={order._id}
                      isOpen={showDurModal}
                      fillId={fill._id}
                      onClose={() => {
                        setShowDurModal(false)
                      }}
                      onURACUpdateCompleted={() => {
                        // run the clinical review after URAC is updated
                        runClinicalReview({
                          variables: {
                            orderId: order._id,
                            fillId: fill._id,
                          },
                        })
                      }}
                    />
                  ) : (
                    <EditUracModal
                      durInputs={durInputs}
                      orderId={order._id}
                      isOpen={showDurModal}
                      fillId={fill._id}
                      forceIsLoading={clinicalReviewIsLoading}
                      onClose={() => {
                        setShowDurModal(false)
                      }}
                      onURACUpdateCompleted={() => {
                        runClinicalReview({
                          variables: {
                            orderId: order._id,
                            fillId: fill._id,
                          },
                        })
                      }}
                    />
                  ))}
              </RXRowTable>
            </RXSegment>
          </RXTable>
        </RXViewContainer>
        <ToolBar paddingTop={order.inTriage && order.inTriage?.reason?.length > 1}>
          {fillNotCancelled && (
            <AuthLimited
              roles={[
                UserRoles.Pharmacist,
                UserRoles.Technician,
                UserRoles.CustomerSupport,
                UserRoles.LeadCustomerSupport,
                UserRoles.Admin,
              ]}
            >
              <EditButton
                orderId={order._id}
                disabled={orderReadonly}
                onClickCallback={() =>
                  trackPV1Event(PV1Events.PV1_EDIT_CLICK.key, {
                    fillId: fill._id,
                  })
                }
              />
            </AuthLimited>
          )}
          <LogButton logs={logs ? uniqueLogEntries(logs) : []} fillId={fill._id} prescriptionId={prescription._id} />
          <NotesButton notesCount={notesBadgeCount} showBadge={notesBadgeCount > 0} />
          <AttachmentsButton
            attachmentsCount={orderAttachments}
            showBadge={parentAttachments || orderAttachments > 0}
          />
          <MedicalHistoryButton fillId={fill._id} patientId={patientId} />
          <OrderButton order={order} />
          <DataButton prescription={prescription} />
          <MonographButton ndc={fill.dispensed.ndc} />
          {allowClaimSummaryButton && (
            <ClaimSummaryButton
              fill={fill}
              order={order}
              rxFillRequest={rxFillRequest}
              allowDisable={true}
              prescription={prescription}
            />
          )}
          {failedClaimReversals && <EditReversalInfoButton setDisplayEditReversalInfo={setDisplayEditReversalInfo} />}
          <DispensingDecisionButton prescription={prescription} fillId={fill._id} />
        </ToolBar>
      </PageContent>
    </>
  )
}

const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`

const StyledBreadCrumb = styled(BreadCrumb)`
  margin-top: 1.25rem;
`

export default PV1View
