import { Chip, Tooltip, Text } from '@truepill/react-capsule'
import { RxFillRequestStatus } from '@truepill/tpos-types'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { accentPurple } from 'styles/styleVariables'
import { ExternalInventorySystemPretty } from 'types'

type InventorySystemTagProps = {
  externalSystemName?: string
  useLozenge?: boolean
  fillStatus?: RxFillRequestStatus
  warehouseLocation?: string
}

const InventorySystemTag = (props: InventorySystemTagProps): JSX.Element => {
  const { externalSystemName, useLozenge, fillStatus, warehouseLocation } = props

  // tooltip has zindex set to prevent it from rendering below other assets
  const renderTag = ({ label, content }: { label: string; content: string }) => (
    <Tooltip
      label={label}
      css={{ backgroundColor: accentPurple, color: 'white', zIndex: 10 }}
      arrowCss={{ borderColor: accentPurple, zIndex: 10 }}
    >
      {useLozenge && <StyledLozenge>{content}</StyledLozenge>}
      {!useLozenge && (
        <Chip css={{ backgroundColor: accentPurple, color: 'white' }}>
          <Text variant="body">{content}</Text>
        </Chip>
      )}
    </Tooltip>
  )

  if (!externalSystemName) return <></>

  if (externalSystemName !== 'pioneer_inventory' && externalSystemName !== 'tecsys') {
    return <></>
  }

  if (!fillStatus) return <></>
  if (
    externalSystemName === 'tecsys' &&
    ![RxFillRequestStatus.Automation, RxFillRequestStatus.PV1, RxFillRequestStatus.Fill].includes(fillStatus)
  )
    return <></>

  if (externalSystemName === 'tecsys') {
    if (warehouseLocation) {
      return renderTag({
        label: `Location for this order is ${warehouseLocation}`,
        content: warehouseLocation,
      })
    } else {
      return renderTag({
        label: `No Location for this order`,
        content: 'No Location',
      })
    }
  }

  return renderTag({
    label: `Inventory for this item was selected from ${ExternalInventorySystemPretty[externalSystemName]}`,
    content: ExternalInventorySystemPretty[externalSystemName],
  })
}

export default InventorySystemTag

const StyledLozenge = styled(Lozenge)`
  background-color: ${accentPurple};
  color: white;
`
