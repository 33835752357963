import moment from 'moment'
import {
  PrescriptionOrigin,
  type Address,
  type Patient,
  type Prescriber,
  type Prescription,
  type TPOSLocation,
} from 'types'
import { formatPhoneNumber, getPrescriberAddress, labelForDAW } from 'utils'
import { compareAddresses, isAddress } from './address'
import { adjustDateToTimezone } from './dates'
import { normalizeProviderType } from './prescriber'
import { calculateExpirationDate } from './Prescription'
import type { RxImagePatient, RxImagePrescriber, RxImagePrescription } from './rxImageFields'
import { capitalize, checkIfProvided } from './textFormatting'

interface RowFieldEntry {
  value: Address | string | number | undefined
  highlightValue?: boolean
  message?: string
}

interface ReviewPatientFields {
  name: RowFieldEntry
  dob: RowFieldEntry
  gender: RowFieldEntry
  address: RowFieldEntry
  phone: RowFieldEntry
}

interface ReviewPrescriberFields {
  prescriber: RowFieldEntry
  credential: RowFieldEntry
  npi: RowFieldEntry
  address: RowFieldEntry
  license: RowFieldEntry
  phone: RowFieldEntry
  fax: RowFieldEntry
  dea: RowFieldEntry
  customer: RowFieldEntry
}

interface ReviewPrescriptionFields {
  medication: RowFieldEntry
  ndc: RowFieldEntry
  ds: RowFieldEntry
  daw: RowFieldEntry
  directions: RowFieldEntry
  written: RowFieldEntry
  expiration: RowFieldEntry
  notes: RowFieldEntry
  triplicate: RowFieldEntry
}

export const highlightValue = (
  rxImageValue: string | number | Address | undefined,
  value: string | number | Address | undefined,
  isFirstFill: boolean = false,
) => {
  if (!rxImageValue || !value) {
    return false
  }
  // address value comparison
  if (typeof rxImageValue === 'object' && isAddress(rxImageValue) && typeof value === 'object' && isAddress(value)) {
    return !compareAddresses(rxImageValue, value)
  }

  if (rxImageValue.toString() !== value.toString() && isFirstFill) {
    return true
  }
  return false
}

const isRxFirstFill = (prescription: Prescription) => {
  return prescription?.nextFillNumber === 0
}

export const buildReviewPatientSectionValues = (
  rxImagePatient: RxImagePatient,
  patient: Patient,
  patientFormData: Patient,
  prescription: Prescription,
  shouldApplyInterfaceImprovements: boolean,
) => {
  const isFirstFill = isRxFirstFill(prescription)
  const isElectronicRx = prescription.origin === PrescriptionOrigin.ELECTRONIC

  const rxPatientData: ReviewPatientFields = {
    name: { value: `${checkIfProvided(rxImagePatient?.firstName)} ${rxImagePatient?.lastName}` },
    dob: { value: rxImagePatient?.dob ? moment(rxImagePatient.dob).format('MM/DD/YYYY') : 'None provided' },
    gender: { value: rxImagePatient?.gender ? capitalize(rxImagePatient?.gender) : 'None provided' },
    address: { value: rxImagePatient?.address },
    phone: { value: checkIfProvided(formatPhoneNumber(rxImagePatient?.communicationNumbers?.phone?.number)) },
  }

  const reviewPatientData: ReviewPatientFields = {
    name: { value: `${patient.firstName} ${patient.lastName}` },
    dob: { value: checkIfProvided(moment(patient.dob).format('MM/DD/YYYY')) },
    gender: { value: checkIfProvided(capitalize(patient.gender)) },
    address: { value: patientFormData.address?.home, highlightValue: false },
    phone: { value: checkIfProvided(formatPhoneNumber(patient.contacts?.phone)) },
  }

  if (isFirstFill && isElectronicRx && shouldApplyInterfaceImprovements) {
    let field: keyof typeof rxPatientData
    for (field in rxPatientData) {
      const highlightColor = highlightValue(rxPatientData[field].value, reviewPatientData[field].value, isFirstFill)
      reviewPatientData[field].highlightValue = highlightColor
      reviewPatientData[field].message = `value for ${field} is different from prescription to system data`
    }
  }

  return { rxPatientData, reviewPatientData }
}

export const buildReviewPrescriberSectionValues = (
  rxImagePrescriber: RxImagePrescriber,
  prescriber: Prescriber,
  prescription: Prescription,
  prescriptionFormData: Prescription,
  prescriptionFormDataCustomerName: string,
  shouldApplyInterfaceImprovements: boolean,
) => {
  const isFirstFill = isRxFirstFill(prescription)
  const isElectronicRx = prescription.origin === PrescriptionOrigin.ELECTRONIC

  const prescriptionPracticeAddress = getPrescriberAddress(prescriptionFormData)

  const rxPrescriberData: ReviewPrescriberFields = {
    prescriber: { value: `${checkIfProvided(rxImagePrescriber?.firstName)} ${rxImagePrescriber?.lastName}` },
    credential: {
      value: rxImagePrescriber?.prescriberType ? normalizeProviderType(rxImagePrescriber.prescriberType) : 'unknown',
    },
    npi: { value: checkIfProvided(rxImagePrescriber?.npi) },
    license: { value: checkIfProvided(rxImagePrescriber?.stateLicense) },
    address: { value: rxImagePrescriber?.address },
    phone: { value: checkIfProvided(formatPhoneNumber(rxImagePrescriber?.communicationNumbers?.phone?.number)) },
    fax: { value: checkIfProvided(formatPhoneNumber(rxImagePrescriber?.communicationNumbers?.fax?.[0]?.number)) },
    dea: { value: checkIfProvided(rxImagePrescriber?.deaNumber) },
    customer: { value: rxImagePrescriber.customer },
  }

  const reviewPrescriberData: ReviewPrescriberFields = {
    prescriber: { value: `${prescriber?.firstName} ${prescriber?.lastName}` },
    credential: {
      value: prescription?.prescriber?.npiDoc?.credential
        ? normalizeProviderType(prescription.prescriber.npiDoc.credential)
        : 'unknown',
    },
    npi: { value: checkIfProvided(prescriber?.npi) },
    license: { value: checkIfProvided(prescriber?.stateLicense) },
    address: { value: prescriptionPracticeAddress },
    phone: {
      value: checkIfProvided(formatPhoneNumber(prescriptionPracticeAddress?.phone || prescriber?.contacts?.phone)),
    },
    fax: { value: checkIfProvided(formatPhoneNumber(prescriptionPracticeAddress?.fax || prescriber?.contacts?.fax)) },
    dea: { value: checkIfProvided(prescriber?.dea) },
    customer: { value: checkIfProvided(prescriptionFormDataCustomerName) },
  }

  if (isFirstFill && isElectronicRx && shouldApplyInterfaceImprovements) {
    let field: keyof typeof rxPrescriberData
    for (field in rxPrescriberData) {
      const highlightColor = highlightValue(
        rxPrescriberData[field].value,
        reviewPrescriberData[field].value,
        isFirstFill,
      )
      reviewPrescriberData[field].highlightValue = highlightColor
      reviewPrescriberData[field].message = `value for ${field} is different from prescription to system data`
    }
  }

  return { rxPrescriberData, reviewPrescriberData }
}

export const buildReviewPrescriptionSectionValues = (
  rxImagePrescription: RxImagePrescription,
  ndcText: string | number,
  dispensedNdcText: string | number,
  prescribedDrugName: string | undefined,
  prescription: Prescription,
  prescriptionFormData: Prescription,
  location: TPOSLocation,
  shouldApplyInterfaceImprovements: boolean,
) => {
  const isFirstFill = isRxFirstFill(prescription)
  const isElectronicRx = prescription.origin === PrescriptionOrigin.ELECTRONIC

  const timezone = location?.timezone
  const prescriptionExpirationDate = calculateExpirationDate(prescription)

  const rxPrescriptionData: ReviewPrescriptionFields = {
    medication: { value: checkIfProvided(rxImagePrescription?.drugDescription) },
    ndc: { value: ndcText },
    ds: { value: checkIfProvided(rxImagePrescription?.daysSupply) },
    daw: { value: labelForDAW(getDAW(prescription)) },
    directions: { value: checkIfProvided(rxImagePrescription?.sig) },
    written: {
      value: rxImagePrescription?.writtenDate
        ? adjustDateToTimezone(rxImagePrescription.writtenDate, timezone)
        : 'None provided',
    },
    expiration: {
      value: rxImagePrescription?.expirationDate
        ? adjustDateToTimezone(rxImagePrescription.expirationDate, timezone)
        : 'None provided',
    },
    notes: { value: checkIfProvided(rxImagePrescription?.note) },
    triplicate: { value: checkIfProvided(prescription.triplicate) },
  }

  const reviewPrescriptionData: ReviewPrescriptionFields = {
    medication: { value: prescribedDrugName },
    ndc: { value: dispensedNdcText },
    ds: { value: prescriptionFormData.daysSupply || rxImagePrescription?.daysSupply },
    daw: { value: labelForDAW(prescription.daw) },
    directions: { value: prescriptionFormData.directions },
    written: {
      value: adjustDateToTimezone(prescription.writtenDate, timezone),
    },
    expiration: {
      value: adjustDateToTimezone(prescriptionExpirationDate, timezone),
    },
    notes: { value: checkIfProvided(prescriptionFormData.note) },
    triplicate: { value: checkIfProvided(prescriptionFormData.triplicate) },
  }

  if (isFirstFill && isElectronicRx && shouldApplyInterfaceImprovements) {
    let field: keyof typeof rxPrescriptionData
    for (field in rxPrescriptionData) {
      const highlightColor = highlightValue(
        rxPrescriptionData[field].value,
        reviewPrescriptionData[field].value,
        isFirstFill,
      )
      reviewPrescriptionData[field].highlightValue = highlightColor
      reviewPrescriptionData[field].message = `value for ${field} is different from prescription to system data`
    }
  }

  return { rxPrescriptionData, reviewPrescriptionData }
}

const getDAW = (prescription: Prescription): number => {
  if (prescription?.directTransfer?.prescription) {
    return prescription.directTransfer.prescription.canSubstitute ? 0 : 1
  }

  if (prescription?.escript?.medicationPrescribed?.substitutions) {
    return Number(prescription.escript.medicationPrescribed.substitutions)
  }

  return prescription.daw
}
