import { useMemo } from 'react'
import type { PriorAuthorizationStatus } from '@truepill/tpos-types'
import { DURStatus, RxFillRequestStatus, CopayStatus, OrderType } from '@truepill/tpos-types'
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg'
import { ReactComponent as MultipetIcon } from 'assets/icons/multipet.svg'
import { RedirectToDashCopay } from 'components/ExternalRedirectLink'
import { LinkToPrescription } from 'components/InternalLink'
import InventorySystemTag from 'components/InventorySystemTag'
import ItemIdDisplay from 'components/ItemIdDisplay'
import PatientHeadingInfo from 'components/PatientHeadingInfo'
import ClinicalReviewTile from 'components/Tiles/ClinicalReviewTile'
import HandlingTagsTile from 'components/Tiles/HandlingTagsTile'
import Lozenge from 'components/Tiles/Lozenge'
import PrintedTile from 'components/Tiles/PrintedTile'
import RobotTile from 'components/Tiles/RobotTile'
import StatusTile from 'components/Tiles/StatusTile'
import useCopayLock from 'hooks/useCopayLock'
import useOrderLock from 'hooks/useOrderLock'
import { last } from 'ramda'
import styled from 'styled-components'
import {
  accentPink,
  bodyPrimaryColor,
  bodySecondaryColor,
  contrastColor,
  darkOrange,
  lightOrange,
  primaryBackgroundColor,
  subduedColor,
} from 'styles/styleVariables'
import type { Order, Prescription, Fill, QueueItem, Patient, OutboundTransfer, PriorAuthorization } from 'types'
import { mostSevereDURStatus, isCopayRequest, isOrder, getItemFill, formatCreatedDate } from 'utils'

function findSmallest(numbers: string | any[]) {
  let smallestNumber = numbers[0]
  for (let i = 0; i < numbers.length; i++) {
    if (numbers[i] < smallestNumber) {
      smallestNumber = numbers[i]
    }
  }
  return smallestNumber
}

type RxHeadingProps = {
  prescription: Prescription
  fill?: Fill
  patient?: Patient
  item: QueueItem
  priorAuthStatus?: PriorAuthorizationStatus
  priorAuthorization?: PriorAuthorization
}

type PrescriptionHeadingProps = {
  prescription?: Prescription
  patient?: Patient
  outboundTransfer?: OutboundTransfer
}

const RxHeading = ({
  prescription,
  fill,
  patient,
  item,
  priorAuthStatus,
  priorAuthorization,
}: RxHeadingProps): JSX.Element => {
  const durStatus = useMemo(
    () => mostSevereDURStatus(last(fill?.durScreenings ?? [])?.results || []),
    [fill?.durScreenings],
  )

  const showDUR = durStatus !== DURStatus.NONE

  // we're using an array here because there will be additional rxCodes to add in the near future
  const specialCodes = []

  // if (heavyAsterisk) {
  //   specialCodes.push('✱')
  // }
  if (prescription?.deaSchedule) {
    specialCodes.push('C')
  }
  // if (dollarSign) {
  //   specialCodes.push('$')
  // }
  // if (SP) {
  //   specialCodes.push('SP')
  // }

  const specialHandlingTags = isOrder(item)
    ? item.rxFillRequests.find(i => i.fillId.toString() === fill?._id)?.specialHandlingTags
    : undefined

  const itemFill = fill ? (isOrder(item) ? getItemFill(item, fill._id) : getItemFill(item, fill._id)) : undefined
  const isOtcOrder = isOrder(item) && item.otcProducts?.length > 0
  const isOtcAndRxOrder =
    isOrder(item) && item.otcProducts?.length > 0 && item.rxFillRequests.filter(i => !i.otcProductId).length > 0
  const isMultiPetOrder = isOrder(item) && item.orderType === OrderType.MultiPet

  const rxFillRequest = useMemo(() => {
    return isOrder(item) ? item.rxFillRequests.find(i => i.fillId.toString() === fill?._id) : undefined
  }, [item, fill?._id])

  return (
    <StyledRxViewHeading data-testid="RxHeading">
      <RxViewHeading>
        <RXFillCode data-testid="rx-fillcode">
          Rx: <LinkToPrescription label={`${fill?.rxFillCode}`} prescriptionId={prescription._id} />
        </RXFillCode>
        {!isOrder(item) && (
          <RedirectContainer>
            <RedirectToDashCopay identifier={item.coreCopayToken ?? ''} />
          </RedirectContainer>
        )}
        <Lozenges data-testid="lozenges">
          {fill?.isReplacement && (
            <Lozenge data-testid="replacement" backgroundColor={subduedColor}>
              RP
            </Lozenge>
          )}
          <ItemLozenge
            item={item}
            prescription={prescription}
            itemDisplay={isOrder(item) ? 'Order' : 'Copay'}
            itemId={'coreOrderId' in item ? item.coreOrderId : item._id}
            displayItemId={isOrder(item)}
          />
          <Lozenge data-testid="location" backgroundColor={contrastColor}>
            {fill?.location?.name}
          </Lozenge>
          <Lozenge data-testid="customer" backgroundColor={accentPink}>
            {prescription?.customer?.name}
          </Lozenge>
          {isMultiPetOrder && <MultipetIcon fill={primaryBackgroundColor} />}
          <HandlingTagsTile specialHandlingTags={specialHandlingTags || []} />
          {(fill?.labelsPrinted ?? 0) > 0 && <PrintedTile />}
          <StatusTile status={priorAuthStatus || itemFill?.status || 'No Fill'} />
          {isOrder(item) && item.isReservedForInvestigation && (
            <Lozenge data-testid="resrvedForInvestigation" color={darkOrange} backgroundColor={lightOrange}>
              reserved
            </Lozenge>
          )}
          {isOrder(item) && fill && <RobotTile {...getItemFill(item, fill._id)} lozenge />}
          {specialCodes.length > 0 && (
            <StyledRXCodesLozenge>
              {specialCodes.map(code => (
                <div key={code}>{code}</div>
              ))}
            </StyledRXCodesLozenge>
          )}
          {showDUR && <ClinicalReviewTile durStatus={durStatus} lozenge />}
          {(isOtcOrder || isOtcAndRxOrder) && (
            <Lozenge
              data-testid={isOtcAndRxOrder ? 'rx-and-otc' : 'otc-only'}
              backgroundColor="#F8F1FF"
              color="#310063"
            >
              {isOtcAndRxOrder ? 'RX + OTC' : 'OTC'}
            </Lozenge>
          )}
          <InventorySystemTag
            externalSystemName={fill?.dispensed?.ffsInventoryInfo?.externalSystemName}
            fillStatus={rxFillRequest?.status}
            useLozenge={true}
            warehouseLocation={fill?.dispensed?.ffsInventoryInfo?.warehouseLocation}
          />
        </Lozenges>
      </RxViewHeading>
      <FillInfo>
        {patient && <PatientHeadingInfo data-testid="patient-name" patientId={patient._id} />}
        {priorAuthorization?.createdAt ? (
          <FillCreationDate>
            <FillCreated>PA created:</FillCreated>
            <DateCreated data-testid="date-created">
              {formatCreatedDate(priorAuthorization.createdAt)?.date}
            </DateCreated>
          </FillCreationDate>
        ) : (
          fill?.createdAt && (
            <FillCreationDate>
              <FillCreated>Fill created:</FillCreated>
              <DateCreated data-testid="date-created">{formatCreatedDate(fill.createdAt)?.date}</DateCreated>
            </FillCreationDate>
          )
        )}
      </FillInfo>
    </StyledRxViewHeading>
  )
}

export const OutboundTransferHeading = ({
  prescription,
  patient,
  outboundTransfer,
}: PrescriptionHeadingProps): JSX.Element => {
  // we're using an array here because there will be additional rxCodes to add in the near future
  const specialCodes = []

  if (prescription?.deaSchedule) {
    specialCodes.push('C')
  }

  return (
    <StyledRxViewHeading data-testid="RxHeading">
      <RxViewHeading>
        <RXFillCode data-testid="rx-fillcode">Rx: {prescription?.rxNumber}</RXFillCode>
        <Lozenges data-testid="lozenges">
          <Lozenge data-testid="replacement" backgroundColor={subduedColor}>
            Transfer: {outboundTransfer?.coreTransferOutToken}
          </Lozenge>
          <Lozenge data-testid="location" backgroundColor={contrastColor}>
            {outboundTransfer?.location?.name}
          </Lozenge>
          <Lozenge data-testid="customer" backgroundColor={accentPink}>
            {outboundTransfer?.customer?.name}
          </Lozenge>
          <StatusTile status={outboundTransfer?.status || 'No Fill'} />
          {specialCodes.length > 0 && (
            <StyledRXCodesLozenge>
              {specialCodes.map(code => (
                <div key={code}>{code}</div>
              ))}
            </StyledRXCodesLozenge>
          )}
        </Lozenges>
      </RxViewHeading>
      <FillInfo>
        {patient && <PatientHeadingInfo data-testid="patient-name" patientId={patient._id} />}
        {prescription?.createdAt && (
          <FillCreationDate>
            <FillCreated>Prescription created:</FillCreated>
            <DateCreated data-testid="date-created">{formatCreatedDate(prescription.createdAt)?.date}</DateCreated>
          </FillCreationDate>
        )}
      </FillInfo>
    </StyledRxViewHeading>
  )
}

type ItemLozengeProps = {
  item: QueueItem
  prescription: Prescription
  itemDisplay: string
  itemId: string
  displayItemId?: boolean
}

const LockWrapperOrder = ({ id }: { id: string }) => {
  const { isOrderLockedByMe } = useOrderLock(id)
  return isOrderLockedByMe ? (
    <LockWrapper role="img" title="This order is locked to your account">
      <LockIcon fill={primaryBackgroundColor} />
    </LockWrapper>
  ) : (
    <></>
  )
}

const LockWrapperCopay = ({ id }: { id: string }) => {
  const { isCopayLockedByMe } = useCopayLock(id)
  return isCopayLockedByMe ? (
    <LockWrapper role="img" title="This copay is locked to your account">
      <LockIcon fill={primaryBackgroundColor} />
    </LockWrapper>
  ) : (
    <></>
  )
}

const Lock = ({ item }: { item: QueueItem }) => {
  const isCopay = isCopayRequest(item)
  return isCopay ? <LockWrapperCopay id={item._id} /> : <LockWrapperOrder id={item._id} />
}

const ItemLozenge = ({
  item,
  prescription,
  itemDisplay,
  itemId,
  displayItemId = true,
}: ItemLozengeProps): JSX.Element => {
  if (!item) {
    return <></>
  }

  const itemFills = isCopayRequest(item) ? item.copayRequestFills : item.rxFillRequests
  const index = itemFills.findIndex(({ prescription: p }) => p._id === prescription._id)

  const fillStageValues = isCopayRequest(item)
    ? item.copayRequestFills.map(i => Object.values(CopayStatus).indexOf(i.status))
    : item.rxFillRequests.map(i => Object.values(RxFillRequestStatus).indexOf(i.status))

  const lowestStageValue = findSmallest(fillStageValues)

  return (
    <StyledItemLozenge data-testid="order-status">
      <Lock item={item} />
      {displayItemId && (
        <>
          {itemDisplay}:
          <ItemDisplayWrapper>
            <ItemIdDisplay id={itemId} customerRef={isCopayRequest(item) ? undefined : item.customerRef} />
          </ItemDisplayWrapper>
        </>
      )}
      {index + 1} of {itemFills.length}
      <ItemNumberDisplay title={'Completion status of fills on this order'}>
        {itemFills.map((itemFill: any, i) => {
          const fillStageValue = isCopayRequest(item)
            ? Object.values(CopayStatus).indexOf(itemFill.status)
            : Object.values(RxFillRequestStatus).indexOf(itemFill.status)
          const isStageComplete = fillStageValue !== lowestStageValue

          return <ItemNumberFillShow role="img" filled={isStageComplete} key={i} />
        })}
      </ItemNumberDisplay>
    </StyledItemLozenge>
  )
}

const LockWrapper = styled.div`
  margin-top: 0.125rem;
  margin-right: 0.3125rem;
`

const Lozenges = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > span {
    margin-left: 0.1rem;
  }
`
const StyledRxViewHeading = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledItemLozenge = styled(Lozenge)`
  background-color: ${contrastColor};
  color: ${primaryBackgroundColor};
  display: flex;
  flex-shrink: 0;
`

const StyledRXCodesLozenge = styled(Lozenge)`
  background-color: ${contrastColor};
  color: ${primaryBackgroundColor};
  font-size: 1rem;
  font-weight: 700;
  padding-top: 0;
  padding-bottom: 0;
  > div {
    padding-left: 0.5rem;
    display: inline-block;
    :first-child {
      padding-left: 0;
    }
  }
`

const RXFillCode = styled.h1`
  font-size: 1.3125rem;
  font-weight: 500;
  color: ${bodyPrimaryColor};
`
const RedirectContainer = styled.div`
  font-size: 1.3125rem;
  font-weight: 500;
`

const ItemNumberFillShow = styled.div<{ filled?: boolean }>`
  height: 0.219rem;
  width: 0.219rem;
  border-radius: 50%;
  align-self: center;
  border: 0.158rem solid #ffffff;
  display: inline-block;
  :not(:last-child) {
    margin-right: 0.25rem;
  }
  background-color: ${({ filled }) => (filled ? primaryBackgroundColor : 'none')};
`
const ItemNumberDisplay = styled.div`
  margin-left: 0.5rem;
  display: flex;
`

const RxViewHeading = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  h1 {
    font-size: 1.3em;
  }
`

const FillInfo = styled.div`
  margin-top: 0.2rem;
  color: ${bodySecondaryColor};
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
`

const FillCreationDate = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const FillCreated = styled.span`
  margin-right: 0.2rem;
  margin-left: 0.7rem;
`

const DateCreated = styled.span`
  font-weight: 400;
`

const ItemDisplayWrapper = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`

export default RxHeading
